import theKooplesLogo from '../../assets/partners/TheKooples.svg';
import maduraLogo from '../../assets/partners/madura.jpg';
import degresLogo from '../../assets/partners/85degres.png';
import admiseLogo from '../../assets/partners/admise_paris.png';
import apcLogo from '../../assets/partners/apc.png';
import artumescoLogo from '../../assets/partners/artumes&co.png';
import hastLogo from '../../assets/partners/hast_paris.png';
import isabelMarantLogo from '../../assets/partners/Isabel-Marant.png';
import jessicaChoayLogo from '../../assets/partners/jessica_choay.png';
import laureDeSagazanLogo from '../../assets/partners/Laure_de_Sagazan.svg';
import luzLogo from '../../assets/partners/luz.png';
import stephanieWolffLogo from '../../assets/partners/stephanie_wolff.png';
import magazellesLogo from '../../assets/partners/magazelles.svg';
import violetteSauvageLogo from '../../assets/partners/violette_sauvage.png';
import carolinaRitzlerLogo from '../../assets/partners/CarolinaRitzler.png';
import karlaOttoLogo from '../../assets/partners/karla_otto.png';
import lexceptionLogo from '../../assets/partners/l_exception.png';
import lesArmoiresDeParisLogo from '../../assets/partners/les_armoires_de_Paris.png';
import sezaneLogo from '../../assets/partners/sezane.png';
import unerobeunsoirLogo from '../../assets/partners/unerobeunsoir.png';
import elisechalminLogo from '../../assets/partners/elisechalmin.png';
import bourrienneLogo from '../../assets/partners/BourrienneParisX.png';
import galeriesLafayetteLogo from '../../assets/partners/galeriesLafayette.svg';
import sessunLogo from '../../assets/partners/sessun.png';
import balzacLogo from '../../assets/partners/balzac.svg';
import bhvLogo from '../../assets/partners/Bhv.png';
import asphalteLogo from '../../assets/partners/Asphalte.png';
import printempsLogo from '../../assets/partners/printemps.jpg';
import aubadeLogo from '../../assets/partners/aubade.jpg';
import maisonStandardsLogo from '../../assets/partners/maison-standards.png';
import socialiteFamilyLogo from '../../assets/partners/socialite-family.png';
import cyrillusLogo from '../../assets/partners/cyrillus.png';
import jacquardFrancaisLogo from '../../assets/partners/jacquard-francais.jpg';

export const partnersData = [
  {
    caption: 'Galeries Lafayette, retouches Paris',
    img: galeriesLafayetteLogo,
    width: '250px',
  },
  {
    caption: 'BHV, retouches Paris',
    img: bhvLogo,
  },
  {
    caption: 'Printemps atelier de retouche',
    img: printempsLogo,
  },
  {
    caption: 'Madura, retouches d\'ourlets de rideaux',
    img: maduraLogo,
  },
  {
    caption: 'Aubade upcycling de vêtements',
    img: aubadeLogo,
  },
  {
    caption: 'Maison Standards atelier de broderie',
    img: maisonStandardsLogo,
  },
  {
    caption: 'Socialite Family création accessoires',
    img: socialiteFamilyLogo,
  },
  {
    caption: 'Cyrillus couturière Paris',
    img: cyrillusLogo,
  },
  {
    caption: 'Le Jacquard Français ',
    img: jacquardFrancaisLogo,
  },
  {
    caption: 'Balzac Paris Vêtements & Accessoires pour femmes',
    img: balzacLogo,
  },
  {
    caption: 'Sessun Prêt-à-porter, chaussures et accessoires',
    img: sessunLogo,
  },
  {
    caption: 'The Kooples couturière costume',
    img: theKooplesLogo,
    width: '300px',
  },
  {
    caption: 'Sézane retoucheur Paris',
    img: sezaneLogo,
    width: '300px',
  },
  {
    caption: 'Asphalte, retouches Paris',
    img: asphalteLogo,
  },
  {
    caption: 'A.P.C., retouches et ourlets sur jeans',
    img: apcLogo,
  },
  {
    caption: 'Isabel Marant, retouches et ourlets sur robes',
    img: isabelMarantLogo,
    width: '300px',
  },
  {
    caption: 'Karla Otto atelier retouches',
    img: karlaOttoLogo,
  },
  {
    caption: 'Laure de Sagazan, retouche de robe de mariée',
    img: laureDeSagazanLogo,
  },
  {
    caption: 'Violette Sauvage, vide-dressing',
    img: violetteSauvageLogo,
  },
  {
    caption: 'Admise Paris, retouches sur tailleur',
    img: admiseLogo,
  },
  {
    caption: '85° retouches de vêtement',
    img: degresLogo,
  },
  {
    caption: 'Artumès & Co cintrage de jupe',
    img: artumescoLogo,
  },
  {
    caption: 'Hast Paris, chemise sur mesure',
    img: hastLogo,
  },
  {
    caption: 'Jessica Choay, retouche à domicile',
    img: jessicaChoayLogo,
  },
  {
    caption: 'Luz maillots de bain',
    img: luzLogo,
  },
  {
    caption: 'Stephanie Wolff service à domicile',
    img: stephanieWolffLogo,
  },
  {
    caption: 'Carolina Ritzler couturier Paris',
    img: carolinaRitzlerLogo,
  },
  {
    caption: 'Magazelles, changement doublure et zip',
    img: magazellesLogo,
    height: '100px',
    width: '75px',
  },
  {
    caption: 'L‘Exception service de retouches Aix Bordeaux Lyon Marseille',
    img: lexceptionLogo,
  },
  {
    caption: 'Les armoires de Paris retoucherie',
    img: lesArmoiresDeParisLogo,
  },
  {
    caption: '1Robe1soir Paris retouche à domicile ouverte le dimanche',
    img: unerobeunsoirLogo,
  },
  {
    caption: 'Elise Chalmin broderie t-shirt et pull',
    img: elisechalminLogo,
  },
  {
    caption: 'Bourrienne Paris X chemise cintrage',
    img: bourrienneLogo,
  },
];
